var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-modify-confirm-add-baggage",
      "centered": "",
      "no-close-on-backdrop": "",
      "no-enforce-focus": "",
      "header-class": "py-50",
      "size": "lg"
    },
    on: {
      "show": _vm.handleShowModal
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('div', {
          staticClass: "w-100 d-flex justify-content-between align-items-center"
        }, [_c('div', {
          staticClass: "text-airline fw_700",
          class: _vm.isMobileView ? 'font-medium-1' : 'font-medium-3'
        }, [_vm._v(" Thanh toán ")]), _c('b-button', {
          staticClass: "p-50",
          attrs: {
            "variant": "flat-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XIcon",
            "size": "24"
          }
        })], 1)], 1)];
      }
    }, {
      key: "modal-footer",
      fn: function fn(_ref2) {
        var close = _ref2.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "px-1 px-md-2",
          attrs: {
            "variant": "outline-secondary",
            "pill": ""
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "fw-700",
          attrs: {
            "variant": ['QH', 'TH', 'AK'].includes(_vm.bookingSource) || _vm.isPayNow ? 'success' : 'warning',
            "pill": "",
            "disabled": !_vm.isConfirm
          },
          on: {
            "click": _vm.handleConfirm
          }
        }, [_vm._v(" " + _vm._s(['QH', 'TH', 'AK'].includes(_vm.bookingSource) || _vm.isPayNow ? 'THANH TOÁN NGAY' : 'THANH TOÁN SAU') + " ")])];
      }
    }])
  }, [_c('div', {
    staticClass: "fw-700 mb-25"
  }, [_vm._v(" Xác nhận thông tin: ")]), _vm._l(_vm.dataShow, function (tripData, indexTripData) {
    return _c('b-card', {
      key: indexTripData,
      staticClass: "border-warning mb-50",
      attrs: {
        "header-bg-variant": "light-warning",
        "header-class": "p-50",
        "body-class": "py-25 px-1"
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('div', {
            staticClass: "text-airline fw_700"
          }, [_c('span', {
            staticClass: "font-small-4"
          }, [_vm._v("Hành trình: ")]), _c('span', [_vm._v(_vm._s("".concat(tripData.trip[0].departure.iataCode, " - ").concat(tripData.trip[tripData.trip.length - 1].arrival.iataCode)) + " (" + _vm._s(_vm.convertISODateTime(tripData.trip[0].departure.at, tripData.trip[0].departure.timeZone).date) + ")")])])];
        },
        proxy: true
      }], null, true)
    }, [_c('CardNamePax', {
      attrs: {
        "arr-pax": tripData.arrPax,
        "name-type": 'hành lý'
      }
    }), _c('div', {
      staticClass: "mb-50"
    }, [_c('span', {
      staticClass: "fw-700"
    }, [_vm._v("Gói đã chọn:")]), _vm._l(tripData.newAddItems, function (bag, itemBag) {
      return _c('div', {
        key: itemBag,
        staticClass: "mb-25"
      }, [_c('div', [_vm._v(_vm._s("- ".concat(_vm.resolveSsrBagsName(bag), " x").concat(bag.quantity)))])]);
    })], 2)], 1);
  }), _c('b-card', {
    attrs: {
      "header-class": "p-1",
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('b-row', {
          staticClass: "w-100 d-flex-between mx-1 mx-lg-3"
        }, [_c('b-col', {
          staticClass: "text-right font-medium-3 fw_700",
          attrs: {
            "cols": "6"
          }
        }, [_vm._v(" Tổng tiền: ")]), _c('b-col', {
          staticClass: "font-medium-3 text-warning fw_700 text-right",
          attrs: {
            "cols": "6"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalPrice)) + " ")])], 1)];
      },
      proxy: true
    }])
  }), _vm.getBookingData && ['VJ'].includes(_vm.bookingSource) ? _c('div', {
    staticClass: "fw-700 mb-25"
  }, [_vm._v(" Lựa chọn thanh toán: ")]) : _vm._e(), _vm.getBookingData && ['VJ'].includes(_vm.bookingSource) ? _c('CardAncillaryPayOptions', {
    attrs: {
      "reservations-data": _vm.getBookingData,
      "is-pay-now": _vm.isPayNow,
      "has-paid-trip": _vm.hasPaidTrip
    },
    on: {
      "update:isPayNow": function updateIsPayNow($event) {
        _vm.isPayNow = $event;
      },
      "update:is-pay-now": function updateIsPayNow($event) {
        _vm.isPayNow = $event;
      }
    }
  }) : _vm._e(), _c('IAmConfirm', {
    attrs: {
      "is-confirm": _vm.isConfirm
    },
    on: {
      "update:isConfirm": function updateIsConfirm($event) {
        _vm.isConfirm = $event;
      },
      "update:is-confirm": function updateIsConfirm($event) {
        _vm.isConfirm = $event;
      }
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }