<template>
  <!-- SECTION modal-modify-ancillary-pay-options -->
  <b-modal
    id="modal-modify-confirm-add-baggage"
    centered
    no-close-on-backdrop
    no-enforce-focus
    header-class="py-50"
    size="lg"
    @show="handleShowModal"
  >
    <template #modal-header="{ close }">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <div
          class="text-airline fw_700"
          :class="isMobileView ? 'font-medium-1' : 'font-medium-3'"
        >
          Thanh toán
        </div>
        <b-button
          variant="flat-secondary"
          class="p-50"
          @click="close()"
        >
          <feather-icon
            icon="XIcon"
            size="24"
          />
        </b-button>
      </div>
    </template>
    <template #modal-footer="{ close }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        class="px-1 px-md-2"
        pill
        @click="close()"
      >
        {{ $t('reservation.back') }}
      </b-button>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="(['QH', 'TH', 'AK'].includes(bookingSource) || isPayNow) ? 'success' : 'warning'"
        class="fw-700"
        pill
        :disabled="!isConfirm"
        @click="handleConfirm"
      >
        {{ (['QH', 'TH', 'AK'].includes(bookingSource) || isPayNow) ? 'THANH TOÁN NGAY' : 'THANH TOÁN SAU' }}
      </b-button>
    </template>

    <div class="fw-700 mb-25">
      Xác nhận thông tin:
    </div>

    <b-card
      v-for="(tripData, indexTripData) of dataShow"
      :key="indexTripData"
      header-bg-variant="light-warning"
      header-class="p-50"
      body-class="py-25 px-1"
      class="border-warning mb-50"
    >
      <template #header>
        <div class="text-airline fw_700">
          <span class="font-small-4">Hành trình: </span>
          <span>{{ `${tripData.trip[0].departure.iataCode} - ${tripData.trip[tripData.trip.length - 1].arrival.iataCode}` }} ({{ convertISODateTime(tripData.trip[0].departure.at, tripData.trip[0].departure.timeZone).date }})</span>
        </div>
      </template>

      <CardNamePax
        :arr-pax="tripData.arrPax"
        :name-type="'hành lý'"
      />

      <div class="mb-50">
        <span class="fw-700">Gói đã chọn:</span>
        <div
          v-for="(bag, itemBag) of tripData.newAddItems"
          :key="itemBag"
          class="mb-25"
        >
          <div>{{ `- ${resolveSsrBagsName(bag)} x${bag.quantity}` }}</div>
        </div>
      </div>
    </b-card>

    <b-card
      header-class="p-1"
      no-body
    >
      <template #header>
        <b-row class="w-100 d-flex-between mx-1 mx-lg-3">
          <b-col
            cols="6"
            class="text-right font-medium-3 fw_700"
          >
            Tổng tiền:
          </b-col>
          <b-col
            cols="6"
            class="font-medium-3 text-warning fw_700 text-right"
          >
            {{ formatCurrency(totalPrice) }}
          </b-col>
        </b-row>
      </template>
    </b-card>

    <div
      v-if="getBookingData && ['VJ'].includes(bookingSource)"
      class="fw-700 mb-25"
    >
      Lựa chọn thanh toán:
    </div>

    <CardAncillaryPayOptions
      v-if="getBookingData && ['VJ'].includes(bookingSource)"
      :reservations-data="getBookingData"
      :is-pay-now.sync="isPayNow"
      :has-paid-trip="hasPaidTrip"
    />

    <IAmConfirm :is-confirm.sync="isConfirm" />
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
  BCard,
  BRow, BCol,
} from 'bootstrap-vue'
import {
  computed,
  ref, watch,
} from '@vue/composition-api'
import {
  cloneDeep, isEmpty, flatten, sum,
} from 'lodash-es'
import Vue from 'vue'

import { resolveSsrBagsName } from '@/constants/selectOptions'
import store from '@/store'
import i18n from '@/libs/i18n'
import IAmPaymentPassword from '@/components/IAmPaymentPassword.vue'

import { convertISODateTime, formatCurrency } from '@core/utils/filter'
import { validatorPaymentPassword } from '@core/utils/validations/validators'

import useReservationHandle from '@reservation/useReservationHandle'
import useAncillaryServicesHandle from '@reservation/reservation-modify/components/detail/ancillary-services/useAncillaryServicesHandle'

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
    CardNamePax: () => import('../components/CardNamePax.vue'),
    IAmConfirm: () => import('@/components/IAmConfirm.vue'),
    CardAncillaryPayOptions: () => import('@reservation/reservation-modify/components/detail/components/CardAncillaryPayOptions.vue'),
  },
  props: {
    arrDataToConfirm: {
      type: [Array, null],
      default: () => [],
    },
  },
  setup(props, { root }) {
    const { modifyAncillary, getBookingData } = useReservationHandle()

    const {
      fnAncillary,
      bookingSource,
      expandItemsQH,
    } = useAncillaryServicesHandle()

    const isPayNow = ref(false)
    const hasPaidTrip = ref(false)
    const dataShow = ref(null)
    const isConfirm = ref(false)
    const totalPrice = ref(0)

    function checkTotalPrice(data) {
      const listPrice = data.map(trip => trip.newAddItems.map(i => (i.fares.find(f => ['ALL', 'ADULT', ''].includes(f.paxType)).total * i.quantity) * trip.arrPax.length))
      totalPrice.value = sum(flatten(listPrice))
    }

    function checkHasPaidTrip(data) {
      if (data.some(trip => isEmpty(trip.eticket))) return
      data.forEach(pax => {
        const listEticketOK = pax.eticket.filter(e => ['TKT'].includes(e.ticketType) && ['OK', 'CKIN'].includes(e.status))
        const bagSegments = pax.addons.map(ad => ad.trip.segments.map(s => ({
          departureDate: convertISODateTime(s.departure.at, s.departure.timeZone).date,
          departure: s.departure.iataCode,
          arrival: s.arrival.iataCode,
        }))).flat()
        const tktSegments = listEticketOK.map(tk => tk.segments.map(s => ({
          departureDate: convertISODateTime(s.departureDate).date,
          departure: s.departure,
          arrival: s.arrival,
        }))).flat()
        if (!isEmpty(listEticketOK)) {
          hasPaidTrip.value = bagSegments.every(bag => tktSegments.some(tkt => tkt.departureDate === bag.departureDate && tkt.departure === bag.departure && tkt.arrival === bag.arrival))
        } else {
          hasPaidTrip.value = false
        }
      })
    }

    watch(dataShow, val => {
      if (!val) return
      const cloneValue = cloneDeep(val)
      checkHasPaidTrip(cloneValue)
      checkTotalPrice(cloneValue)
    }, { deep: true })

    function handleShowModal() {
      dataShow.value = cloneDeep(props.arrDataToConfirm).map(trip => ({
        ...trip,
        newAddItems: trip.newAddItems.every(i => i.code)
          ? trip.newAddItems.sort((a, b) => (b.code > a.code) ? 1 : (a.code > b.code) ? -1 : 0)
          : trip.newAddItems,
      }))

      isConfirm.value = false

      if ((['VJ'].includes(bookingSource.value) && (getBookingData.value.totalAmountToBePaid === 0)) || ['QH', 'AK', 'TH'].includes(bookingSource.value)) {
        isPayNow.value = true
      } else {
        isPayNow.value = false
      }
    }

    const getItineraries = (segments, passenger) => {
      const result = []

      segments.forEach(seg => {
        result.push({
          airline: seg.airline,
          departure: seg.departure.iataCode,
          arrival: seg.arrival.iataCode,
          departureDate: convertISODateTime(seg.departure.at, seg.departure.timeZone).ISOdatetime,
          arrivalDate: convertISODateTime(seg.arrival.at, seg.arrival.timeZone).ISOdatetime,
          flightNumber: seg.flightNumber,
          segmentId: seg.segmentId,
          fareBasisCode: ['VU'].includes(bookingSource.value) ? (seg?.fareBasisCode || '') : (passenger?.fareBasisCode?.split(' | ')[Number(seg?.segmentId) - 1] || ''),
          bookingClass: seg.bookingClass || '',
          groupClass: seg.groupClass || '',
          bookingStatus: seg.bookingStatus,
          ...(['VU', 'VJ'].includes(bookingSource.value) && { itineraryId: seg.itineraryId }),
        })
      })
      return result
    }

    const getPayload = dataTrips => dataTrips.map(tripItem => tripItem.arrPax.map(pax => tripItem.newAddItems.map(bag => ({
      ancillaryCode: getBookingData.value.source === 'F1' ? bag.key : bag.code,
      ancillaryValue: getBookingData.value.source === 'F1' ? bag.code : bag.ssrValue || bag.code,
      ancillaryType: ['VN1A', 'VN1A_MT'].includes(bookingSource.value) ? 'BAGGAGE' : undefined,
      paxInfo: {
        type: pax.paxType,
        paxId: pax.paxId,
        firstName: pax.firstName,
        lastName: pax.lastName,
        nameId: pax?.nameId || undefined,
      },
      itineraries: getItineraries(tripItem.trip, pax),
      quantity: bag.quantity,
      total: (bag?.fares?.find(f => ['ALL', 'ADULT', ''].includes(f.paxType))?.total * bag.quantity) || 1,
      details: bag.details.map(dt => ({
        weight: String(dt.weight),
        unit: dt.unit,
      })),
      operation: 'ADD',
      id: ['VU', 'QH'].includes(bookingSource.value) ? tripItem.trip[0].segmentId : '999',
    })))).flat(2)

    const getMeEnablePaymentPassword = computed(() => store.getters['userStore/getMeEnablePaymentPassword'])

    async function handleConfirm() {
      const data = await getPayload(dataShow.value)
      const resultPayload = ['QH'].includes(bookingSource.value) ? expandItemsQH(data) : data // QH thì gửi lên tách quantity ra từng item
      let paymentPassword
      if (getMeEnablePaymentPassword.value && isPayNow.value) {
        paymentPassword = await Vue.swal({
          title: this.$t('myAccount.paymentPassword.title'),
          html: '<div id="i-am-payment-password"></div>',
          didOpen: () => {
            new Vue({
              render: h => h(IAmPaymentPassword, { props: { typePaymentPassword: 'off' } }),
              i18n,
            }).$mount('#i-am-payment-password')
          },
          focusConfirm: false,
          allowOutsideClick: true,
          preConfirm: () => new Promise(resolve => {
            const pwd = document.getElementById('swal-input-payment-password-1').value
            if (!validatorPaymentPassword(pwd)) {
              Vue.swal.showValidationMessage(this.$t('myAccount.paymentPassword.errorInput'))
            } else { resolve(pwd) }
          }),
          showCancelButton: true,
          confirmButtonText: this.$t('confirmation'),
          cancelButtonText: this.$t('cancel'),
          buttonsStyling: true,
          customClass: {
            confirmButton: 'btn btn-gradient',
            cancelButton: 'btn btn-danger mr-1',
          },
          reverseButtons: true,
        })
        if (paymentPassword.isDenied || paymentPassword.isDismissed) {
          return
        }
      }

      const paymentData = {
        isPayNow: isPayNow.value,
        paymentPassword: paymentPassword?.value || '',
      }

      this.$bvModal.show('modal-api-loading')
      modifyAncillary(getBookingData.value, resultPayload, paymentData)
        .then((() => {
          const modalShow = Array.from(window.document.querySelectorAll('.modal.show'))
            .map(el => el.id)
            // .filter(id => id === 'modal-reservation-modify-update-baggage'
            // || id === 'modal-modify-ancillary-pay-options')
          modalShow.forEach(id => {
            root.$root.$emit('bv::hide::modal', id)
          })
        }))
        .finally(() => this.$bvModal.hide('modal-api-loading'))
    }

    return {
      fnAncillary,
      bookingSource,
      getBookingData,
      hasPaidTrip,
      isPayNow,
      dataShow,
      totalPrice,
      isConfirm,

      handleConfirm,
      handleShowModal,
      formatCurrency,
      resolveSsrBagsName,
      convertISODateTime,
    }
  },
}
</script>
